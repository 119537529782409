body {
  font-family: sans-serif;;
  font-style: normal;
  background-color: #1a1d26;
  max-width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

select {
  display: block;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23242835%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1rem top 0.9rem, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0.4rem 2rem 0.4rem 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border: 1px solid #6370e5;
  border-radius: 8px;
  line-height: 1.5;
  color: #6370e5;
  transition: all 200ms ease-in-out;
  font-size: 0.889rem;
}






/* This is for editing the blocknative pop up window   */
/* https://docs.blocknative.com/onboard/core */
:root {

  /*--onboard-primary-100: blue;
  --onboard-gray-600: blue;

  --onboard-gray-100: blue;
  --onboard-gray-200: blue;
  --onboard-gray-300: blue;
  --onboard-gray-400: blue;
  --onboard-gray-500: blue;
  --onboard-gray-600: blue;
  --onboard-gray-700: blue;

  --onboard-primary-1: blue;
  --onboard-primary-100: blue;
  --onboard-primary-200: blue;
  --onboard-primary-300: blue;
  --onboard-primary-400: blue;
  --onboard-primary-500: blue;
  --onboard-primary-600: blue;
  --onboard-primary-700: blue;*/

  

  --onboard-font-family-normal: monospace; 
  --onboard-font-family-semibold: monospace; 
  --onboard-connect-content-width: 50vw; 
  --onboard-connect-content-height: 450px;
  --onboard-wallet-columns: 2;
  --onboard-connect-sidebar-background: black;
  --onboard-connect-sidebar-color: white; 
  --onboard-connect-sidebar-progress-background: #fffb96;
  --onboard-connect-sidebar-progress-color: green;
  --onboard-connect-header-background: #black; 
  --onboard-connect-header-color: black;  
  --onboard-link-color: black;
  --onboard-close-button-background: white;  
  --onboard-close-button-color: black;
  --onboard-checkbox-background: green;
  --onboard-checkbox-color: white;
  --onboard-wallet-button-background-hover: rgba(161, 219, 243, 0.863); 
  --onboard-wallet-button-color: #444444; 
  --onboard-wallet-button-border-color: #b967FF;
  --onboard-wallet-app-icon-border-color: #b967FF;



  
  /*--onboard-modal-backdrop: */
 /* --onboard-modal-box-shadow: */
 

  

  /* CUSTOMIZE THE COLOR  PALLETTE */
  /*--onboard-white: white;
  --onboard-black: black;
  --onboard-primary-1: #2f80ed;
  --onboard-primary-100: #eff1fc;
  --onboard-primary-200: #d0d4f7;
  --onboard-primary-300: #b1b8f2;
  --onboard-primary-400: #929bed;
  --onboard-primary-500: #6370e5;
  --onboard-primary-600: #454ea0;
  --onboard-primary-700: #323873;
  --onboard-gray-100: #ebebed;
  --onboard-gray-200: #c2c4c9;
  --onboard-gray-300: #999ca5;
  --onboard-gray-400: #707481;
  --onboard-gray-500: #33394b;
  --onboard-gray-600: #242835;
  --onboard-gray-700: #1a1d26;
  --onboard-success-100: #d1fae3;
  --onboard-success-200: #baf7d5;
  --onboard-success-300: #a4f4c6;
  --onboard-success-400: #8df2b8;
  --onboard-success-500: #5aec99;
  --onboard-success-600: #18ce66;
  --onboard-success-700: #129b4d;
  --onboard-danger-100: #ffe5e6;
  --onboard-danger-200: #ffcccc;
  --onboard-danger-300: #ffb3b3;
  --onboard-danger-400: #ff8080;
  --onboard-danger-500: #ff4f4f;
  --onboard-danger-600: #cc0000;
  --onboard-danger-700: #660000;
  --onboard-warning-100: #ffefcc;
  --onboard-warning-200: #ffe7b3;
  --onboard-warning-300: #ffd780;
  --onboard-warning-400: #ffc74c;
  --onboard-warning-500: #ffaf00;
  --onboard-warning-600: #cc8c00;
  --onboard-warning-700: #664600;*/

  /* CUSTOMIZE SECTIONS OF THE CONNECT MODAL */
  /*--onboard-connect-content-width
  --onboard-connect-content-height
  --onboard-wallet-columns
  --onboard-connect-sidebar-background
  --onboard-connect-sidebar-color
  --onboard-connect-sidebar-progress-background
  --onboard-connect-sidebar-progress-color
  --onboard-connect-header-background
  --onboard-connect-header-color
  --onboard-link-color
  --onboard-close-button-background
  --onboard-close-button-color
  --onboard-checkbox-background
  --onboard-checkbox-color
  --onboard-wallet-button-background
  --onboard-wallet-button-background-hover
  --onboard-wallet-button-color
  --onboard-wallet-button-border-color
  --onboard-wallet-app-icon-border-color*/
  
  /* CUSTOMIZE THE CONNECT MODAL */
  /*--onboard-modal-border-radius
  --onboard-modal-backdrop
  --onboard-modal-box-shadow*/

  /* CUSTOMIZE THE ACTION REQUIRED MODAL */
  /*--onboard-action-required-modal-background*/

  /* FONTS */
  /*--onboard-font-family-normal: Sofia Pro;
  --onboard-font-family-semibold: Sofia Pro Semibold;
  --onboard-font-family-light: Sofia Pro Light;

  --onboard-font-size-1: 3rem;
  --onboard-font-size-2: 2.25rem;
  --onboard-font-size-3: 1.5rem;
  --onboard-font-size-4: 1.25rem;
  --onboard-font-size-5: 1rem;
  --onboard-font-size-6: 0.875rem;
  --onboard-font-size-7: 0.75rem;*/

  /* SPACING */
  /*--onboard-spacing-1: 3rem;
  --onboard-spacing-2: 2rem;
  --onboard-spacing-3: 1.5rem;
  --onboard-spacing-4: 1rem;
  --onboard-spacing-5: 0.5rem;*/

  
  
  /* BORDER RADIUS */
  /*--onboard-border-radius-1: 24px;
  --onboard-border-radius-2: 20px;
  --onboard-border-radius-3: 16px;*/

  /* SHADOWS */
  /*--onboard-shadow-0: none;
  --onboard-shadow-1: 0px 4px 12px rgba(0, 0, 0, 0.1);
  --onboard-shadow-2: inset 0px -1px 0px rgba(0, 0, 0, 0.1);*/
  
  /* MAIN MODAL POSITIONING */
  /*--onboard-modal-z-index
  --onboard-modal-top
  --onboard-modal-bottom
  --onboard-modal-right
  --onboard-modal-left*/

  

  /* HD WALLET ACCOUNT SELECT MODAL POSITIONING */
  /*--onboard-account-select-modal-z-index
  --onboard-account-select-modal-top
  --onboard-account-select-modal-bottom
  --onboard-account-select-modal-right
  --onboard-account-select-modal-left*/

  /* MAGIC WALLET MODAL POSITIONING */
  /*--onboard-login-modal-z-index
  --onboard-login-modal-top
  --onboard-login-modal-bottom
  --onboard-login-modal-right
  --onboard-login-modal-left*/

  /* MAGIC WALLET MODAL POSITIONING */
  /*--onboard-login-modal-z-index
  --onboard-login-modal-top
  --onboard-login-modal-bottom
  --onboard-login-modal-right
  --onboard-login-modal-left*/
  
  /* HARDWARE WALLET STYLES  */
  /* *if not set will fallback to variables with `--onboard` prefix shown above */

  --account-select-modal-background: black;
  /* COLORS */
  /*--account-select-modal-white: white;
  --account-select-modal-black: black;
  --account-select-modal-primary-100: #eff1fc;
  --account-select-modal-primary-200: #d0d4f7;
  --account-select-modal-primary-300: #b1b8f2;
  --account-select-modal-primary-500: #6370e5;
  --account-select-modal-primary-600: #454ea0;
  --account-select-modal-gray-100: #ebebed;
  --account-select-modal-gray-200: #c2c4c9;
  --account-select-modal-gray-300: #999ca5;
  --account-select-modal-gray-500: #33394b;
  --account-select-modal-gray-700: #1a1d26;
  --account-select-modal-danger-500: #ff4f4f;*/

  /* FONTS */
  /*--account-select-modal-font-family-normal: Sofia Pro;
  --account-select-modal-font-family-light: Sofia Pro Light;
  --account-select-modal-font-size-5: 1rem;
  --account-select-modal-font-size-7: .75rem;
  --account-select-modal-font-line-height-1: 24px;*/

  /* SPACING */
  /*--account-select-modal-margin-4: 1rem;
  --account-select-modal-margin-5: 0.5rem;*/
}


.networkSelect{
  width: 10%;
  justify-content: center;
  align-items: center;
  display: contents;
}

.HomeApp {
  height: 100%;
  width: 100%;
  background-color: #0d1116;    
  position: fixed;  
}

.grad {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -5;
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.13) 53%, #000 97%);
}

.brand {
  position: fixed;
  left: 5vw;
  top: 2.5vw;
  width: 15vw;
  height: 5vw;
  background-image: url('./images/Operation-Access-Squared.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blockNativeDivCSS{
  color: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.blocknativeButton{
  background: #ffffffee;
  font-size: 0.889rem;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 0.55rem 1.4rem;
  color: #6370e5;
  margin: 0.4rem 0.75rem;
}

.selectChainDiv{
  color: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.connectAndDisconnectDiv{
  color: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
}

.disabledPage {
  pointer-events: none;
  opacity: 0.2;
}

.connectLocation{
  
}

.connectButtonStyle{
  top: 6.7vh;
  right: 5vw;
  display: flex;
  width: 15vw;
  height: 2.8vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: white;
}

.mainContainer {    
    
}

.mintButtonLocation{
  
}

.mintButtonStyle{
  display: flex;
  width: 15vw;
  height: 2.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: #cfff48;
  bottom: 5vh;
  right: 32vw;
}

.presalemintButtonStyle{
  display: flex;
  width: 15vw;
  height: 2.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: #cfff48;
  bottom: 15vh;
  right: 32vw;
}

.ownermintButtonStyle{
  display: flex;
  width: 15vw;
  height: 2.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: #cfff48;
  bottom: 25vh;
  right: 32vw;
}

.helpButtonLocationTurnOn{  
  position: fixed;
}

.helpButtonLocationTurnOff{
  top: 29.3vh;
  right: 28vw;
  position: fixed; 
}

.helpButtonStyleTurnOn{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 7vh;
  right: 25vw;
  width: 15vw;
  height: 2.5vw;
  margin-left: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2vw;
  background-color: transparent;
  font-family: Cofosans, sans-serif;
  color: white;
  font-size: 0.8vw;
  line-height: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  cursor: pointer;
}

.helpButtonStyleTurnOff{  
  background-color: transparent;
  border: transparent;
}

.helpDivCSS{  
  position: fixed;
  top: 25vh;  
  width: 50vw;
  left: 25%;
  height: 40vh;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 3px;
  border-color: #cfff48;
  border-radius: 5vw;
  background-color: #12110d;
  display: flex;
}

.helpPopUpFont1{
  margin-top: 40px;
  margin-bottom: 0px;
  font-family: Mortend, sans-serif;
  color: #cfff48;
  font-size: 1.2vw;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 10px;
  text-align: center;
}

.helpPopUpFont2{
  margin-top: 18px;
  margin-bottom: 0px;
  font-family: Mortend, sans-serif;
  color: #fff;
  font-size: 3.4vw;
  line-height: 1; 
  text-align: center;
}

.helpPopUpFont3{
  margin-top: 0px;
  margin-bottom: 18px;
  font-family: Mortend, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  line-height: 1;
  text-align: center;
}

.helpPopUpFont4{  
  font-family: Cofosans, sans-serif;
  color: #fff;
  font-size: .8vw;
  line-height: 1.2;
  text-align: center;
  margin: -10px;
}

.launch-onboard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 2.5vw;
  max-height: 50px;
  max-width: 250px;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2vw;
  background-color: #cfff48;
  font-family: Cofosans, sans-serif;
  color: #000;
  font-size: 0.8vw;
  line-height: 1;
  cursor: pointer;
}

.launch-onboard:hover {
  border-style: solid;
  border-width: 2px;
  border-color: #cfff48;
  background-color: transparent;
  color: #cfff48;
}

.launchOnBoardLocation{
  display: block;  
  margin-bottom: 40px;
}

/* https://codepen.io/sosuke/pen/Pjoqqp    - this website gets a new filter color for our SVG files*/
.closeIconSVG{
  width: 30px;
  height: 30px;
  filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(259deg) brightness(104%) contrast(104%);   
}

.closeIconSVG:hover{  
  opacity: .5;  
  cursor: pointer;   
}

.moreInfoButtonLocationTurnOn{   
  position: fixed;
}

.moreInfoButtonStyleTurnOn{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 2.5vw;
  position: fixed;
  bottom: 5vh;
  left: 32vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2vw;
  background-color: #fff;
  font-family: Cofosans, sans-serif;
  color: #000;
  font-size: 0.8vw;
  line-height: 1;
  cursor: pointer;
}

.moreInfoButtonStyleTurnOn:hover{
  background-color: transparent;
  color: white;
  border-color: white;
}

.selectWalletButtonStyle{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 2.5vw;
  position: fixed;
  top: 7vh;
  right: 7vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2vw;
  background-color: #fff;
  font-family: Cofosans, sans-serif;
  color: #000;
  font-size: 0.8vw;
  line-height: 1;
  cursor: pointer;
}

.selectWalletButtonStyle:hover{
  background-color: transparent;
  color: white;
  border-color: white;
}


.moreInfoDivCSS{
  position: fixed;
  top: 15vh;
  left: 25%;
  display: flex;
  width: 50vw;
  height: 70vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 3px;
  border-color: #cfff48;
  border-radius: 5vw;
  background-color: #12110d;
}

.moreInfoButtonLocationTurnOff{
  top: 17vh;
  right: 28vw;
  position: fixed; 
}

.moreInfoButtonStyleTurnOff{  
  background-color: transparent;
  border: transparent;
}

.moreInfoPopUpFont1{
  padding-top: 18px;
  margin-bottom: 0px;
  font-family: Mortend, sans-serif;
  color: #cfff48;
  font-size: 1.2vw;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 10px;
  text-align: center;
}

.moreInfoPopUpFont2{
  margin-top: 18px;
  margin-bottom: 0px;
  font-family: Mortend, sans-serif;
  color: #fff;
  font-size: 3.4vw;
  line-height: 1; 
  text-align: center;
}

.moreInfoPopUpFont3{
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Mortend, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  line-height: 1;
  text-align: center;
}

.moreInfoPopUpFont4{  
  margin-top: 0px;
  width: 80%;
  font-family: Cofosans, sans-serif;
  color: #fff;
  font-size: .8vw;
  line-height: 1.2;
  text-align: center;
}

.moreInfoPopUpFont5{
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Mortend, sans-serif;
  color: #cfff48;
  font-size: 1.2vw;
  line-height: 1.1;
  font-weight: 400;
  text-align: center;
  letter-spacing: 5px;
}

.moreInfoPopUpFont5Mobile{
  display: none;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Mortend, sans-serif;
  color: #cfff48;
  font-size: 1.2vw;
  line-height: 1.1;
  font-weight: 400;
  text-align: center;
  letter-spacing: 5px;
}

.moreInfoPopUpFont6 {
  width: auto;
  margin-bottom: 0px;
  font-family: Cofosans, sans-serif;
  color: #fff;
  font-size: 0.8vw;
  line-height: 1.2;
  text-align: left;
}

.environment {
  margin-top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.environmentPic {
  width: 20%;
  margin-right: 29px;
  background-image: url('./images/environment.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.environment-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}



/* https://codepen.io/sosuke/pen/Pjoqqp    - this website gets a new filter color for our SVG files*/
.closeIconSVGMoreInfo{
  width: 30px;
  height: 30px;
  filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(259deg) brightness(104%) contrast(104%);   
  z-index: 50;
}

.closeIconSVGMoreInfo:hover{  
  opacity: .5;  
  cursor: pointer;   
}

.swal-confirm{
  background-color: #cfff48;
  color: #000;
  border: 0;
  width: 150px;
  padding: 12px;
  border-radius: 0.25em;
  border-color: #fff;
  font-size: 1em;  
  cursor: pointer;
}

.swal-error{
  background-color: #fff;
  color: #000;
  border: 0;
  width: 60px;
  padding: 12px;
  border-radius: 0.25em;
  border-color: #fff;
  font-size: 1em;  
  cursor: pointer;
}










.mint-deets {
  margin-top: 0px;
  margin-bottom: 18px;
  font-family: Mortend, sans-serif;
  color: #fff;
  font-size: 3.3vw;
  line-height: 1;
}

.mint-deets._2 {
  font-size: 2.1vw;
}

.mint-deets._3 {
  font-size: 2.6vw;
}

.green {
  color: #cfff48;
}

.image-2 {
  width: 60%;
  height: auto;
}

.image-mobile {
  display: none;
  width: 60%;
  height: auto;
}



















@media screen and (max-width: 479px) {

  .selectWalletButtonStyle{
    width: 40vw;
    height: 5vh;
    position: fixed;
    top: 10vh;
    right: 5vw;
    margin-left: 0px;
    font-family: Cofosans, sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
  }

  .mint-deets {
    font-size: 8.5vw;
  }

  .mint-deets._2 {
    font-size: 5.5vw;
  }

  .mint-deets._3 {
    font-size: 6.7vw;
  }

  .image-2 {
    display: none;
    width: 90%;
  }

  .image-mobile {
    display: block;
    width: 90%;
  }

  .brand {
    left: 5vw;
    top: 5vw;
    width: 30vw;
    height: 10vw;
  }

  .helpButtonStyleTurnOn{
    width: 40vw;
    height: 5vh;
    position: fixed;
    top: 10vh;
    left: 5vw;
    margin-left: 0px;
    font-family: Cofosans, sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
  }

  .connectButtonStyle{
    width: 40vw;
    height: 5vh;
    position: fixed;
    top: 10vh;
    right: 5vw;
    margin-left: 0px;
    font-family: Cofosans, sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
    border-color: white;    
  }

  .moreInfoButtonStyleTurnOn{
    width: 90vw;
    height: 5vh;
    max-width: none;
    font-family: Cofosans, sans-serif;
    position: fixed;
    left: 5vw;
    bottom: 10vh;
    font-size: 5vw;
    font-weight: 400;
  }

  .mintButtonStyle{
    width: 90vw;
    background-color: #cfff48;
    font-size: 5vw;
    bottom: 3vh;
    left: 5vw;   
    height: 5vh;    
  }

  .helpDivCSS{  
    width: 90vw;
    left: 5%;
  }

  .helpPopUpFont1{
    width: 80vw;
    margin-top: 0vh;
    font-size: 2.5vw;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .helpPopUpFont2{
    font-size: 6vw;
  }
  
  .helpPopUpFont3{
    font-size: 5.2vw;
  }
  
  .helpPopUpFont4{  
    font-size: 3vw;
  }

  .launch-onboard {
    width: 40vw;
    height: 5vh;
    font-family: Cofosans, sans-serif;
    font-size: 3.5vw;
  }

  .helpButtonLocationTurnOff{
    top: 26.5vh;
    right: 8vw;
  }

  .moreInfoDivCSS{
    width: 90vw;
    height: 50vh;
    left: 5%;
    top: 20vh;    
    overflow: auto;    
    padding-top: 50vh;
    padding-bottom: 10vh;
  }

  .moreInfoPopUpFont1{
    width: 80vw;
    margin-top: 50vh;
    font-size: 2.5vw;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .moreInfoPopUpFont2{
    font-size: 6vw;
  }
  
  .moreInfoPopUpFont3{
    font-size: 5.2vw;
  }
  
  .moreInfoPopUpFont4{  
    width: 60vw;
    font-size: 3vw;
  }
  
  .moreInfoPopUpFont5{
    display: none;
  }
  
  .moreInfoPopUpFont6 {
    width: 60vw;
    font-size: 3vw;
  }
  
  .environment {
    width: 80vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .environmentPic {
    width: 5vh;
    height: 5vh;
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  
  .environment-vertical {
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .moreInfoPopUpFont5Mobile{
    display: flex;
    text-align: center;
    font-size: 3vw;
    line-height: 1.2;
  }

  .moreInfoButtonLocationTurnOff {
    top: 22.1vh;
    right: 7vw;  
  }
}


/* loding */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.loading {
  width: 100%;
  /* height: 100%;  */
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: .5rem;
  row-gap: 1.5rem;
  position: fixed;
  top: 50%;
}

.loading:after {
  /* content: "Logging you in"; */
  color: #1a7ca7;
  flex: 0 100%;
  font: 700 1.3rem "Caveat", cursive;
  text-align: center;
}

.loading div {
  background-color: #1a7ca7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: loading-effect 1s cubic-bezier(.77, .8, .58, 1) infinite
    var(--delay, .2s) alternate-reverse;
}

.loading div:nth-child(2) {
  --delay: .4s;
}
.loading div:nth-child(3) {
  --delay: .6s;
}
.loading div:nth-child(4) {
  --delay: .8s;
}
.loading div:nth-child(5) {
  --delay: 1s;
}

@keyframes loading-effect {
  0% {
    box-shadow: 0 0 4px 1px #fff3;
    opacity: .2;
    transform: translateY(3px) scale(1.1);
  }

  100% {
    opacity: .8;
    transform: translateY(-3px);
  }
}

@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-BOLD.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-MEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-REGULAR.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-BLACK.TTF') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend';
  src: url('./fonts/Mortend---Bold.ttf') format('truetype'), url('./fonts/Mortend---Extrabold.ttf') format('truetype'), url('./fonts/Mortend---Bold.otf') format('opentype'), url('./fonts/Mortend---Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend';
  src: url('./fonts/Mortend---Regular.ttf') format('truetype'), url('./fonts/Mortend---Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend';
  src: url('./fonts/Mortend---Light.ttf') format('truetype'), url('./fonts/Mortend---Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend outline';
  src: url('./fonts/Mortend---Extrabold-Outline.ttf') format('truetype'), url('./fonts/Mortend---Extrabold-Outline.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}




